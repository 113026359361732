<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div id="chatsoptify">
      <h1>Loading .....</h1>
    </div>
  </main>
</template>
<script>
export default {
  setup() {
    var requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch("https://spaceseats.io/api/ticket/chatsoptify", requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result);
        document.getElementById("chatsoptify").innerHTML = result.data;
      })
      .catch(error => console.log("error", error));
  }
};
</script>
